define([
  'jquery',
  'underscore',
  './item',
  'modules/shop.cash-register-retail/templates/onlineFoodOrder/overview/item/siteDishItem.hbs',

  'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/siteDishActionPopup',
  'modules/shop.cash-register-retail/components/onlineFoodOrder',
], (
  $, _, View, Template,
  SiteDishActionPopup, OnlineFoodOrderComponent,
) => View.extend({

  template: Template,

  onClick() {
    const view = new SiteDishActionPopup({
      model: this.model,
    });
    view.open();
  },

  serializeData() {
    return OnlineFoodOrderComponent.getSitedishDataFromModel(this.model);
  },
}));
