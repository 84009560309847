define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/orders/product.hbs',

  './productInput',
  'modules/shop.cash-register-retail/views/popups/messagePopup',

  'modules/shop.cash-register-retail/components/productSearch',
  'modules/common/components/locale',

  'upx.modules/ShopModule/collections/Order',
], (
  $, _, Backbone, Template,
  ProductInputView, MessagePopupView,
  ProductSearch, Locale,
  OrderCollection,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  regions: {
    input: '[data-region="input"]',
    popup: '[data-region="popup"]',
  },

  initialize(options) {
    this.parentView = options.parentView;
    this.productSearchModel = new Backbone.Model({ value: '' });
    this.productSearchModel.on('change:value', _.debounce(function () {
      this.search();
    }, 100), this);
  },

  onRender() {
    this.renderInput();
  },

  renderInput() {
    const region = this.getRegion('input');
    const view = new ProductInputView({
      model: this.productSearchModel,
    });
    region.show(view);
  },

  search() {
    let q = this.productSearchModel.get('value');

    if (q !== '') {
      q = q.trim();
      const def = new $.Deferred();
      this.triggerMethod('loader:start', def, 'overview');
      this.renderInput();

      const updateData = {
        productSearch: null,
        shop_product_id: null,
      };

      this.serialSearch(q)
        .then((hasSerial) => {
          if (hasSerial) {
            updateData.productSearch = q;
          }
          ProductSearch.searchProduct(q)
            .then((searchResponse) => {
              updateData.shop_product_id = searchResponse.model.get('id');
              this.model.set(updateData);
            }, () => {
              this.model.set(updateData);
              if (!hasSerial) {
                def.reject();
                const view = new MessagePopupView();
                view.open(
                  `${Locale.translate('could_not_find_a_product_or_order_with_serial')}: ${q}`,
                );
              }
            });
        }, () => {
          this.model.set(updateData);
          def.resolve();
        });
    }
  },

  serialSearch(query) {
    const def = new $.Deferred();

    const filters = this.parentView.getFilters();
    filters.push({
      name: 'product_serial_no__used',
      val: query,
    });

    const parameters = this.parentView.getParameters(filters);
    parameters.limit = 1;

    const orderCollection = new OrderCollection();
    orderCollection.fetch({ params: parameters })
      .then(() => {
        def.resolve(orderCollection.length > 0);
      }, def.reject);

    return def;
  },

}));
