define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/memberCards/list/collection.hbs',
  'modules/shop.cash-register-retail/views/memberCards/list/item',
], (
  $, _, Backbone, Template, ItemView,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  className: 'customer-list',

  childView: ItemView,

  childEvents: {
    'child:clicked': 'childClicked',
  },

  childClicked(view, model) {
    this.triggerMethod('card:selected', model);
  },

  childViewContainer: 'tbody',

  events: {
    'click [data-action="more"]': 'moreClicked',
  },

  ui: {
    more: '[data-ui="more"]',
  },

  moreClicked() {
    if (this.collection.canFetchNext()) {
      const self = this;
      this.triggerMethod('start:loader');
      this.collection.fetchNext()
        .always(() => {
          self.btnCheck();
          self.triggerMethod('stop:loader');
        });
    }
  },

  btnCheck() {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  },

  onShow() {
    this.btnCheck();
  },

}));
