define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/openOrders/list/list.hbs',
  './listItem',

  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

], (
  $, _, Backbone, Template, ItemView,
  ReceiptPrinter,
) => Backbone.Marionette.CompositeView.extend({

  template: Template,

  childView: ItemView,

  childViewOptions() {
    return {
      loader: this.options.loader,
    };
  },

  childViewContainer: 'tbody',

  serializeData() {
    return {
      hasReceiptPrinter: ReceiptPrinter.isWantedType(),
    };
  },

}));
