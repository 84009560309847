define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/actionPopup',
], (
  $, _, Backbone,
  FoodOrderConfirmPopup,
) => Backbone.Marionette.ItemView.extend({

  className: 'btn designer-box rounded online-food-item',

  events: {
    click: 'onClick',
  },

  modelEvents: {
    change: 'onModelChange',
    'change:status': 'onModelCollectionChange',
  },

  onClick() {
    const view = new FoodOrderConfirmPopup({
      model: this.model,
    });
    view.open();
  },

  onModelChange() {
    this.render();
  },
  onModelCollectionChange() {
    this.model.collection.trigger('collectionView:render');
  },

  serializeData() {
    throw new Error('serializeData should be extended');
  },
}));
