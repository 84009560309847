define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/onlineFoodOrder/overview/layout.hbs',
  'modules/shop.cash-register-retail/views/onlineFoodOrder/overview/collection',

  'modules/shop.cash-register-retail/collections/upx/OnlineFoodOrder',
  'modules/shop.cash-register-retail/components/onlineFoodOrder.js',
  'modules/common/components/moment',
], (
  $, _, Backbone, Template, ItemCollectionView,
  OnlineFoodOrderCollection, OnlineFoodOrderComponent, Moment,
) => {
  const View = Backbone.Marionette.LayoutView.extend({

    className: 'online-food-overview',

    template: Template,

    regions: {
      new: '[data-region=list-new]',
      confirmed: '[data-region=list-confirmed]',
      'in-kitchen': '[data-region=list-in-kitchen]',
      finished: '[data-region=list-complete]',
    },

    onShow() {
      [
        OnlineFoodOrderComponent.STATUS_NEW,
        OnlineFoodOrderComponent.STATUS_CONFIRMED,
        OnlineFoodOrderComponent.STATUS_IN_KITCHEN,
      ].forEach((status) => {
        this.getRegion(status).show(
          new ItemCollectionView({
            collection: OnlineFoodOrderCollection,
            filter: (model) => model.get('status') === status,
          }),
        );
      });
      this.getRegion('finished').show(
        new ItemCollectionView({
          collection: OnlineFoodOrderCollection,
          filter: (model) => model.get('status') === OnlineFoodOrderComponent.STATUS_FINISHED
            || model.get('status') === OnlineFoodOrderComponent.STATUS_CANCELED,
          viewComparator: (a, b) => {
            const atime = Moment(a.get('date_updated')).unix();
            const btime = Moment(b.get('date_updated')).unix();
            if (atime === btime) {
              return 0;
            }
            return atime > btime ? -1 : 0;
          },
        }),
      );
    },
  });

  return View;
});
