define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/openOrders/list/layout.hbs',

  './list',
  'modules/shop.cash-register-retail/views/popups/massPackingSlipPrintPopup',

  'modules/shop.cash-register-retail/collections/upx/Order',
  'modules/shop.cash-register-retail/models/upx/Order',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',
  'modules/shop.cash-register-retail/components/openOrder.js',

  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/shop.cash-register-retail/components/datepickk',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template,
  ListView, MassPackingSlipPrintPopupView,
  OrderCollection, OrderModel, ReceiptPrinter, OpenOrder,
  Locale, Moment, Datepickk,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'open-orders',

  regions: {
    items: '[data-region="items"]',
  },

  events: {
    'change [data-action="search"]': 'searchChanged',
    'keyup [data-action="search"]': 'searchChanged',

    'click [data-action="more"]': 'moreClicked',
    'touchend [data-action="more"]': 'moreClicked',

    'click [data-action="clear"]': 'clearClicked',
    'touchend [data-action="clear"]': 'clearClicked',

    'click @ui.dayPicker': 'dayPickerClicked',
    'click @ui.pickupDateFilter': 'dayPickerClicked',
    'click @ui.massPrint': 'massPrintClicked',
  },

  ui: {
    search: '[data-ui="search"]',
    more: '[data-ui="more"]',
    pickupDateFilter: '[data-ui="pickup-date-filter"]',
    dayPicker: '[data-ui="day-picker"]',
    massPrint: '[data-ui="mass-print"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  initialize({ orderId }) {
    this.collection = new OrderCollection();
    this.orderId = orderId;

    this.pickupDateFrom = null;
    this.pickupDateTo = null;
  },

  searchChanged: _.debounce(function () {
    this.updateCollection();
  }, 500),

  moreClicked: _.debounce(function () {
    if (this.collection.canFetchNext()) {
      const def = this.loader.startLoader('more');
      const self = this;
      this.collection.fetchNext()
        .always(() => {
          self.checkMore();
          def.resolve();
        });
    }
  }, 500),

  openOrder() {
    const model = new OrderModel({ id: this.orderId });
    const def = this.loader.startLoader('main');
    OpenOrder
      .detailsPopup({
        model,
      })
      .then(def.resolve, def.reject);
  },

  clearClicked() {
    this.ui.search.val('');
    this.ui.search.trigger('keyup');
    this.ui.search.trigger('change');
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();
  },

  clearDateFilter() {
    this.pickupDateFrom = null;
    this.pickupDateTo = null;
    this.ui.pickupDateFilter.val('');
  },

  dayPickerClicked() {
    const datepicker = this.getDatePicker();
    datepicker.onConfirm = () => {
      const dates = datepicker.selectedDates;
      this.clearDateFilter();
      this.filterByPickupDate(dates);
    };

    datepicker.show();
  },

  massPrintClicked() {
    const unshippedOrders = new Backbone.Collection(this.collection.filter((model) => !model.get('is_shipped')));

    const popupView = new MassPackingSlipPrintPopupView({
      orderCollection: unshippedOrders,
    });

    popupView.open(Locale.translate('printing_{orders_count}_open_orders', {
      orders_count: unshippedOrders.length,
    }));
  },

  filterByPickupDate(dates) {
    if (dates.length === 1) {
      const dateFrom = dates[0];
      this.pickupDateFrom = Moment(dateFrom).format('YYYY-MM-DD');
      this.ui.pickupDateFilter.val(Moment(dateFrom).locale(Locale.getLocale()).format('LL'));
    } else if (dates.length === 2) {
      const dateFrom = dates[0];
      const dateTo = dates[1];

      this.pickupDateFrom = Moment(dateFrom).format('YYYY-MM-DD');
      this.pickupDateTo = Moment(dateTo).format('YYYY-MM-DD');

      this.ui.pickupDateFilter.val(`${Moment(dateFrom).locale(Locale.getLocale()).format('LL')} / ${Moment(dateTo).locale(Locale.getLocale()).format('LL')}`);
    }

    this.updateCollection();
  },

  getDatePicker() {
    const datepicker = new Datepickk();

    datepicker.lang = Locale.getLocale();
    datepicker.button = Locale.translate('ok');
    datepicker.today = true;
    datepicker.maxSelections = 2;
    datepicker.minDate = new Moment();
    datepicker.range = true;

    return datepicker;
  },

  checkMore() {
    if (!this.isDestroyed) {
      if (this.collection.canFetchNext()) {
        this.ui.more.show();
      } else {
        this.ui.more.hide();
      }
    }
  },

  updateCollection() {
    const def = this.loader.startLoader('main');
    const filters = [
      {
        name: 'is_contract__=',
        val: '0',
      }, {
        name: 'is_delivered__=',
        val: '0',
      }, {
        name: 'status__not_in_list',
        multi_val: [
          'cancelled',
          'complete',
          'on_hold',
          'refunded',
          'concept',
        ],
      }];

    if (this.pickupDateFrom) {
      filters.push({
        name: 'pickup_date__>=',
        val: this.pickupDateFrom,
      });
    }

    if (this.pickupDateTo) {
      filters.push({
        name: 'pickup_date__<=',
        val: this.pickupDateTo,
      });
    }

    const value = this.ui.search.val();
    const query = value.trim();
    if (query.length > 0) {
      filters.push({
        name: 'search__word',
        val: query,
      });
    }

    const params = {
      params: {
        start: 0,
        limit: 20,
        sort: [{
          name: 'date_purchased',
          dir: 'desc',
        }],
        filters,
      },
    };

    const self = this;
    this.collection.fetch(params)
      .always(() => {
        self.checkMore();
        def.resolve();
        this.ui.pickupDateFilter.attr('disabled', 'disabled');
      });
  },

  onShow() {
    this.updateCollection();
    // Set the focus to the search view and popup the keyboard by `clicking` on it
    this.ui.search.focus();
    this.ui.search.click();

    this.collection.on('list:reload', this.searchChanged, this);
  },

  onDestroy() {
    this.collection.off('list:reload', this.searchChanged, this);
  },
  onRender() {
    this.renderItems();

    if (this.orderId) {
      setTimeout(() => this.openOrder());
    }
  },

  renderItems() {
    const region = this.getRegion('items');
    const view = new ListView({
      collection: this.collection,
      loader: this.loader,
    });
    region.show(view);
  },

  serializeData() {
    return {
      showPackbonPrint: ReceiptPrinter.isWantedType(),
    };
  },

}));
