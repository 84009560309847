define([
  'jquery',
  'underscore',
  './item',
  'modules/shop.cash-register-retail/templates/onlineFoodOrder/overview/item/localTomorrowItem.hbs',

  'modules/shop.cash-register-retail/views/popups/onlineFoodOrder/localTomorrowActionPopup',
  'modules/shop.cash-register-retail/components/onlineFoodOrder',
], (
  $, _, View, Template,
  LocalTomorrowActionPopup, OnlineFoodOrderComponent,
) => View.extend({

  className: 'btn designer-box rounded online-food-item-local-tomorrow',

  template: Template,

  onClick() {
    const view = new LocalTomorrowActionPopup({
      model: this.model,
    });
    view.open();
  },

  serializeData() {
    return OnlineFoodOrderComponent.getLocalTomorrowDataFromModel(this.model);
  },
}));
