define([
  'jquery',
  'underscore',
  'backbone',

  './item/siteDishItem',
  './item/localTomorrowItem',

  'modules/shop.cash-register-retail/components/onlineFoodOrder',
], (
  $, _, Backbone,
  SiteDishItemView, LocalTomorrowItemView,
  OnlineFoodOrderComponent,
) => Backbone.Marionette.CollectionView.extend({

  className: 'online-food-collection',

  getChildView(model) {
    const shopTypeAlias = model.get('shop.shop_type.alias');

    switch (shopTypeAlias) {
      case OnlineFoodOrderComponent.SITEDISH_SHOP_TYPE_ALIAS:
        return SiteDishItemView;
      case OnlineFoodOrderComponent.LOCALTOMORROW_SHOP_TYPE_ALIAS:
        return LocalTomorrowItemView;
      default:
        console.warn(`Unknown shop type: ${model.get('shop.shop_type.alias')}`);
    }
  },

  initialize() {
    this.listenTo(this.collection, 'collectionView:render', _.debounce(() => this.render(), 5));
  },
}));
