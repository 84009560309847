define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/newsLetters/list/listItem.hbs',

  'modules/common/components/locale',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone, Template,
  Locale,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  events: {
    'click [data-action="next"]': 'nextClicked',
    'touchend [data-action="next"]': 'nextClicked',

    'click [data-action="previous"]': 'previousClicked',
    'touchend [data-action="previous"]': 'previousClicked',

    'click [data-action="read"]': 'readClicked',
    'touchend [data-action="read"]': 'readClicked',
  },

  readClicked() {
    this.model.collection.trigger('layout:swap', 'details', { model: this.model });
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  serializeData() {
    return this.model.toJSON();
  },

}));
